<template>
  <div class="resume">
    <SectionHeading heading="Resume" />
    <p class="resume__link">
      <a
        href="https://www.dropbox.com/s/atffimmvnkhe6dr/Rohith%20PR%20Resume.pdf?dl=0&s=sl"
        target="_blank"
      >
        View PDF Resume
      </a>
    </p>
  </div>
</template>

<script>
import SectionHeading from './sectionHeading'

export default {
  name: 'Resume',
  components: {
    SectionHeading,
  },
}
</script>
