<template>
  <div class="contact">
    <SectionHeading heading="Contact" />
    <p class="contact__email">
      praroh number(two) [ at ] gmail.com
    </p>
  </div>
</template>

<script>
import SectionHeading from './sectionHeading'

export default {
  name: 'Contact',
  components: {
    SectionHeading,
  },
}
</script>
