<template>
  <div>
    <div class="row intro">
      <div class="col-sm-12">
        <div class="intro__container">
          <h1 class="intro__name">ROHITH PR</h1>
          <h2 class="intro__subtitle">Full Stack Web Developer</h2>
        </div>
      </div>
      <div class="col-sm-12">
        <div class="intro__container">
          <hr class="intro__sep">
          <a target="_blank" href="https://github.com/rohithpr" class="intro__social-link u-hover-zoom"><div>Github</div></a>
          <a target="_blank" href="https://linkedin.com/in/rohithpr/" class="intro__social-link u-hover-zoom"><div>Linkedin</div></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Introduction'
}
</script>

<style>
.u-box {
  height: 5rem;
  width: 5rem;
  display: inline-block;
  margin: .5rem;
}
</style>
